export function classNames(
    ...classes: (string | null | undefined | false)[]
): string {
    return classes.filter(Boolean).join(' ')
}

export const getAccessibleColor = (hex: string) => {
    const [r, g, b] = parseHex(hex)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000000' : '#FFFFFF'
}

///////////////////////////////////////////////////////////////////////////////
// Change hex color into RGB
///////////////////////////////////////////////////////////////////////////////
export const getRGBColor = (hex: string, type: string) => {
    const [r, g, b] = parseHex(hex)
    return `--color-${type}: ${r}, ${g}, ${b};`
}

function parseHex (hex: string): [number, number, number] {
    let color = hex.replace(/#/g, '')
    // if shorthand notation is passed in
    if (color.length !== 6) {
        color = `${color}${color}`
    }
    // rgb values
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)

    return [r, g, b]
}
