/* This example requires Tailwind CSS v2.0+ */
import { Switch } from '@headlessui/react'
import { FlagIcon } from '@heroicons/react/outline'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type FeatureToggleProps = {
  name: string
  description: string
  isEnabled: boolean
  onChange: (change: boolean) => void
}

export default function FeatureToggle({
  name,
  description,
  isEnabled,
  onChange,
}: FeatureToggleProps) {


  return (
    <>
      <Switch.Group as="div" className="relative group py-5 flex-col flex sm:flex-row sm:items-center sm:justify-between space-x-5 space-y-5 sm:space-y-0">
        <div className='flex space-x-5 items-start overflow-auto'>
          <span
            className='bg-skin-primary bg-opacity-10 inline-flex items-center justify-center h-10 w-10 rounded-lg flex-shrink-0'
          >
            <FlagIcon className="h-6 w-6 text-skin-primary" aria-hidden="true" />
          </span>
          <span className="flex-grow flex flex-col overflow-auto break-words">
            <Switch.Label as="span" className="text-left text-sm font-medium text-gray-900" passive>
              {name}
            </Switch.Label>
            <Switch.Description as="span" className="text-left text-sm text-gray-500">
              {description}
            </Switch.Description>
          </span>
        </div>
        <Switch
          checked={isEnabled}
          onChange={onChange}
          className={classNames(
            isEnabled ? 'bg-skin-secondary' : 'bg-skin-secondary bg-opacity-30',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary self-end sm:self-center'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              isEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          >
            <span
              className={classNames(
                isEnabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-gray-500" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={classNames(
                isEnabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-skin-secondary" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
      </Switch.Group>
    </>
  )
}
