import { useEffect, useState } from 'react'
import './App.css'
import FeatureToggleList from './FeatureToggleList'
import PoweredBy, { PoweredByAlignment } from './PoweredBy'
import { getRGBColor, getAccessibleColor } from './utils'
import getConfig from './getConfig'
import FeedbackAlert, { VARIANT as FEEDBACK_ALERT_VARIANT } from './components/FeedbackAlert'

type OptInSettings = {
  _project: string
  enabled: boolean
  title: string
  description: string
  imageURL?: string
  colors: {
    primary: string
    secondary: string
  }
  poweredByAlignment: PoweredByAlignment
}

type OptInConfig = {
  settings: OptInSettings
  features: {
    id: string
    name: string
    description: string
    status: boolean
  }[]
}

const location = new URL(window.location.href)
const queryParams = new URLSearchParams(location.search)

const SDK_KEY = queryParams.get('sdkKey') || queryParams.get('envKey') || ''
const USER_ID = queryParams.get('userId') || ''
const isPreview = queryParams.get('preview') === 'true'
let isFetchingConfig = true

const previewDefaultConfig = {
  settings: {
    _project: '1122',
    enabled: true,
    imageURL: '',
    title: '',
    description: '',
    colors: {
      primary: '#1D4ED8',
      secondary: '#DBEAFE',
    },
    poweredByAlignment: PoweredByAlignment.center,
  },
  features: [
    {
      id: '1231',
      name: 'New Feature 1',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus augue at odio finibus.',
      status: true,
    },
    {
      id: '1232',
      name: 'New Feature 2',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus augue at odio finibus.',
      status: false,
    },
  ],
}

export const sendMessageToAdjustIframeHeight = () => {
  const message = {
    type: 'DVC.optIn.updateHeight',
    height: `${document.body.scrollHeight}px`,
  }
  window.parent.postMessage(message, '*')
}

function App() {
  const [config, setConfig] = useState<OptInConfig>({
    features: [],
    settings: {
      _project: '',
      enabled: false,
      imageURL: '',
      title: '',
      description: '',
      colors: {
        primary: '',
        secondary: '',
      },
      poweredByAlignment: PoweredByAlignment.center,
    },
  })

  const colors = config.settings.colors

  const [error, setError] = useState('')

  const updatePreviewConfig = (event: MessageEvent<unknown>) => {
    const { data } = event as { data: { type: string, settings: OptInSettings } }
    if (data.type === 'DVC.optIn.updatePreview' && data.settings) {
      let settings: OptInSettings = data.settings
      setConfig({ ...previewDefaultConfig, settings })
    }
  }

  useEffect(() => {
    const fetch = async () => {
      if (USER_ID.trim().length === 0 || USER_ID === '{{user_id}}') {
        console.error('A user id must be provided for the Opt In to be registered')
      } else {
        const response = await getConfig(SDK_KEY, USER_ID)
        if (response != null) {
          isFetchingConfig = false
          setConfig(response.data)
        }
      }
    }

    if (isPreview) {
      setConfig(previewDefaultConfig)
      window.addEventListener('message', updatePreviewConfig)
    } else {
      void fetch()
    }

    return () => {
      window.removeEventListener('message', updatePreviewConfig)
    }
  }, [])

  useEffect(() => {
    sendMessageToAdjustIframeHeight()
  }, [config.features])

  useEffect(() => {
    if (isPreview) {
      sendMessageToAdjustIframeHeight()
    }
  }, [config.settings])

  const color1 = colors.primary || '#2563eb'
  const color2 = colors.secondary || '#2dd4bf'
  const primaryColor = getRGBColor(color1, 'primary')
  const secondaryColor = getRGBColor(color2, 'secondary')
  const a11yColor = getRGBColor(getAccessibleColor(color1), 'a11y')

  return (
    <div className="App">
      <style>:root {`{${primaryColor} ${secondaryColor} ${a11yColor}}`}</style>
      <div className="mx-auto max-w-7xl px-1 py-1 md:px-4 md:py-6 md:pb-0 lg:px-8">
        <div className="place-content-center max-w-7xl space-y-8 md:space-y-12 pb-5">
          <div className="text-gray-900 relative bg-white pt-0 shadow rounded-lg md:pt-5 min-h-[300px]">
            <FeatureToggleList
              isPreview={isPreview}
              config={config}
              userId={USER_ID}
              projectId={config.settings._project}
              error={error}
              setError={setError}
              onLogoLoad={sendMessageToAdjustIframeHeight}
              onLogoError={sendMessageToAdjustIframeHeight}
              isFetchingConfig={isFetchingConfig}
            />
            {error.trim().length > 0 && <FeedbackAlert message={error} variant={FEEDBACK_ALERT_VARIANT.error} />}
          </div>
        </div>
        <PoweredBy alignment={config.settings.poweredByAlignment} />
      </div>
    </div>
  )
}

export default App
