import {formValues} from "./FeatureToggleList";
import axios, {AxiosResponse} from "axios";

export default async function updateOptIn(values : formValues) {
  const location = new URL(window.location.href)
  const queryParams = new URLSearchParams(location.search)

  const SDK_KEY = queryParams.get("sdkKey") || queryParams.get("envKey") || ''
  const USER_ID = queryParams.get("userId") || ''

  return await axios.put(`https://sdk-api.devcycle.com/v1/optIns/${USER_ID}`, values,
    {
      headers : {'Authorization': SDK_KEY,
        'Content-Type': 'application/json'
      }
    }).catch((error) => {
      throw new Error(error.message)
  }) as AxiosResponse
}
