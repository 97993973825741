import { classNames } from "./utils";

export enum PoweredByAlignment {
  center = "center",
  left = "left",
  right = "right",
  hidden = "hidden",
}

type PoweredByProps = {
  alignment: PoweredByAlignment;
};

const devcycleLink = 'https://www.devcycle.com?utm_source=product&utm_medium=feature-opt-in&utm_campaign=dvc-logo'


export default function PoweredBy({ alignment }: PoweredByProps) {
  return (
    <div
      className={classNames(
        "px-2 pb-4",
        alignment === PoweredByAlignment.center
          ? "mx-auto max-w-sm justify-center pb-4 sm:px-6 lg:px-8 lg:pb-4"
          : "px-2 pb-4",
        alignment === PoweredByAlignment.left && "justify-start",
        alignment === PoweredByAlignment.right && "justify-end",
        alignment === PoweredByAlignment.hidden && "hidden",
        "flex"
      )}
    >
      <div
        className={classNames(
          "flex items-center border-gray-300 fill-gray-300 px-0 text-gray-300",
        )}
      >
        <p className="mt-0.5 mr-2.5 text-sm font-medium tracking-wider">
          Powered by
        </p>
        <a
          href={devcycleLink}
          target="_tab"
        >
          <img
            width={"100"}
            height="33"
            src={"/togglebot-dark.svg"}
            alt="DevCycle"
          />
        </a>
      </div>
    </div>
  );
}
