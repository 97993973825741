/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/outline'
import { classNames } from './utils'

type buttonProps = {
  formIsDirty: boolean
  isPreview: boolean
}

export default function SaveButton({formIsDirty, isPreview} : buttonProps) {
  
  return (
    <>
      <div className="py-5 flex justify-center sm:justify-end">
        <button
          type="submit"
          disabled={!(formIsDirty && !isPreview)}
          className={classNames(
            formIsDirty ? 'inline-flex justify-center py-2 px-4 border-transparent shadow-sm text-sm font-medium rounded-md border-2 text-white bg-skin-primary hover:bg-opacity-10 hover:text-skin-primary hover:border-skin-primary'
            : 'bg-opacity-50 cursor-not-allowed inline-flex justify-center py-2 px-4 border-transparent shadow-sm text-sm font-medium rounded-md border-2 text-white bg-skin-primary items-center'
          )}
        >
          <CheckCircleIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Save Preferences
        </button>
      </div>
    </>
  )
}
