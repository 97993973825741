import {
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from '@heroicons/react/solid'
import { useEffect } from 'react'
import { sendMessageToAdjustIframeHeight } from '../../App'
import { classNames } from '../../utils'

export enum VARIANT {
  warning = 'warning',
  error = 'error',
  success = 'success',
}

type FeedbackAlertVariant = {
  bg: string
  text: string
  subText: string
  icon: React.ReactNode
}

const variantMap: Record<VARIANT, FeedbackAlertVariant> = {
  [VARIANT.warning]: {
    bg: 'bg-yellow-50',
    text: 'text-yellow-800',
    subText: 'text-yellow-700',
    icon: (
      <ExclamationIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
    ),
  },
  [VARIANT.error]: {
    bg: 'bg-pink-50',
    text: 'text-pink-800',
    subText: 'text-pink-700',
    icon: <XCircleIcon className="w-5 h-5 text-pink-700" aria-hidden="true" />,
  },
  [VARIANT.success]: {
    bg: 'bg-green-50',
    text: 'text-green-800',
    subText: 'text-green-700',
    icon: (
      <CheckCircleIcon className="w-5 h-5 text-green-400" aria-hidden="true" />
    ),
  },
}

type FeedbackAlertProps = {
  message?: React.ReactNode
  subMessage?: React.ReactNode
  variant?: VARIANT
  isCentered?: boolean
  link?: string
  linkText?: string
}

export const FeedbackAlert = ({
  message,
  subMessage,
  variant = VARIANT.warning,
  isCentered = false,
  link,
  linkText,
}: FeedbackAlertProps): React.ReactElement => {

  useEffect(() => {
    sendMessageToAdjustIframeHeight()
  }, [])

  return (
    <div className={classNames('rounded-md p-4', variantMap[`${variant}`].bg)}>
      <div className={isCentered ? 'flex justify-center w-full' : 'flex'}>
        <div className="flex-shrink-0">{variantMap[`${variant}`].icon}</div>
        <div className="ml-3">
          <h3
            className={classNames(
              'text-sm font-medium',
              variantMap[`${variant}`].text,
            )}
          >
            {message}{' '}
            {link && (
              <a className="text-blue-600" href={link}>
                {' '}
                {linkText}{' '}
              </a>
            )}
          </h3>
          {subMessage && (
            <div
              className={classNames(
                'mt-2 text-sm',
                variantMap[`${variant}`].subText,
              )}
            >
              {subMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedbackAlert
