import axios, {AxiosResponse} from "axios";

export default async function getConfig(sdkKey: string, user_id: string): Promise<AxiosResponse> {
  return await axios.get('https://sdk-api.devcycle.com/v1/optInConfig', {
    params: {
      sdkKey,
      user_id,
      isAnonymous: false,
      platform: 'web',
      platformVersion: 'unknown',
      deviceModel: window.navigator.userAgent,
      sdkType: 'client',
      sdkVersion: '1.0.0',
      createdDate: new Date(),
      lastSeenDate: new Date()
    }
  }).catch(function(error) {
    console.log('Config request failed: ' + error)
  }) as AxiosResponse
}

